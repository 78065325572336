<script setup lang="ts">
import { KippieIconButton } from "../";
import { useSwiper } from "swiper/vue";

interface Props {
	role: "next" | "prev";
}

defineProps<Props>();

const swiper = useSwiper();
</script>

<template>
	<div class="absolute top-1/2 -translate-y-1/2 z-[1]" :class="[role === 'prev' ? 'left-0' : 'right-0']">
		<KippieIconButton
			:icon="`chevron-${role === 'prev' ? 'left' : 'right'}`"
			@click="role === 'prev' ? swiper.slidePrev() : swiper.slideNext()"
		/>
	</div>
</template>
